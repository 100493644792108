<style lang="scss" scoped>
.cont {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0.88rem;
	background-color: $color_main;
	color: #fff;
	font-size: 0.36rem;
	line-height: 0.88rem;
	text-align: center;
	padding: 0 0.3rem;
	z-index: 10;
	box-sizing: border-box;
}
.back {
	float: left;
	width: 0.32rem;
	height: 0.32rem;
	align-items: center;
	background-image: url('../assets/icon/back.png');
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: 0.3rem;
	margin-right: -0.32rem;
}
.backImage2 {
	background-image: url('../assets/icon/back2.png');
}
.back_dom {
	height: 0.88rem;
}
</style>

<template>
	<div class="top_nav">
		<div class="cont" :style="{ backgroundColor: bgColor }">
			<span @click="back" v-show="showBack" :class="['back', { backImage2: backImage }]"></span>
			<span>
				<slot>顶部导航</slot>
			</span>
		</div>
		<div class="back_dom"></div>
	</div>
</template>

<script>
export default {
	name: 'topNav' /* 顶部导航 */,
	props: {
		// 是否显示返回按钮
		showBack: {
			type: Boolean,
			required: false,
			default: true,
		},
		bgColor: {
			type: String,
			default: '#2594EF',
		},
		backImage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	methods: {
		// 返回点击事件
		back() {
			this.$emit('back');
		},
	},
};
</script>
