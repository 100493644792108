<style lang="scss" scoped></style>

<template>
	<div>
		<van-popup v-model="showPop" round position="bottom">
			<van-picker show-toolbar :title="title" :columns="columns" :visible-item-count="visibleItemCount" @cancel="onCancel" @confirm="onConfirm" @change="onChange" />
		</van-popup>
	</div>
</template>

<script>
import { Picker, Popup } from 'vant';

export default {
	name: 'pPicker', // 带弹出层的picker
	components: {
		[Picker.name]: Picker,
		[Popup.name]: Popup,
	},
	model: {
		prop: 'show',
	},
	props: {
		show: {
			type: [Boolean, Number, String],
			require: true,
		},

		// 标题
		title: {
			type: String,
			required: false,
			default: '',
		},

		// 可选择的列
		columns: {
			type: Array,
			require: false,
			default: function () {
				return [];
			},
		},

		// 可见的选项个数
		visibleItemCount: {
			type: [Number, String],
			required: false,
			default: 6,
		},
	},
	data() {
		return {};
	},
	computed: {
		showPop: {
			get() {
				return this.show;
			},
			set(val) {
				this.$emit('input', val);
			},
		},
	},
	created() {},
	methods: {
		// 取消
		onCancel(v) {
			this.showPop = false;
			this.$emit('cancel', v);
		},

		// 确定
		onConfirm(v) {
			this.showPop = false;
			this.$emit('confirm', v);
		},

		// 改变
		onChange(el, v) {
			this.$emit('change', v);
		},
	},
};
</script>
