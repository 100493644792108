<style lang="scss" scoped>
.p {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
}
.page_footer {
	text-align: center;
	font-size: 12px;
	line-height: 0.4rem;
	font-weight: normal;
	color: $font_color_info;
	margin-top: 0.2rem;
}
</style>

<template>
	<div class="page_footer" :class="{ p: p }">本服务由北京赛福哈博保险经纪有限公司提供</div>
</template>

<script>
export default {
	name: 'pageFooter',
	props: {
		p: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
	},
	data() {
		return {};
	},
};
</script>
