<template>
	<a class="ly-tab-item" :style="$parent.value === id ? activeStyle : {}" @click="onItemClicked">
		<div class="ly-tab-item-icon" v-if="$parent.fixBottom">
			<slot name="icon"></slot>
		</div>
		<div class="ly-tab-item-label">
			<slot></slot>
		</div>
	</a>
</template>

<script>
export default {
	name: 'LyTabItem',
	computed: {
		activeStyle() {
			return {
				color: this.$parent.activeColor,
			};
		},
	},
	data() {
		return {
			id: (this.$parent.$children.length || 1) - 1,
		};
	},
	methods: {
		onItemClicked() {
			this.$parent.$emit('input', this.id);
		},
	},
};
</script>

<style lang="scss" scoped>
.ly-tab-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	text-align: center;
}

.ly-tab-item-icon {
	margin: 0 auto 5px;
}

.ly-tab-item-label {
	padding: 0.14rem 0.25rem;
	border: 1px solid;
	border-radius: 4px;
	color: $color_main;
	border-color: $color_main;
	font-size: 0.28rem;
}
.ly-tab-item.active .ly-tab-item-label {
	background-color: $color_main;
	color: #fff;
}
</style>
