// 全局组件
import Vue from 'vue';

import topNav from './topNav';
import lyTab from './ly-tab/index.vue';
import activeTitle from './activeTitle.vue';
import priceBuyBtn from './priceBuyBtn.vue';
import bottomInfoPop from './bottomInfoPop.vue';
import pPicker from './pPicker.vue';

import pageHeader from './pageHeader.vue';
import pageFooter from './pageFooter.vue';
import loading from './loading.vue';

Vue.component('topNav', topNav);
Vue.component('lyTab', lyTab);
Vue.component('activeTitle', activeTitle);
Vue.component('priceBuyBtn', priceBuyBtn);
Vue.component('bottomInfoPop', bottomInfoPop);
Vue.component('pageHeader', pageHeader);
Vue.component('pageFooter', pageFooter);
Vue.component('loading', loading);
Vue.component('pPicker', pPicker);
