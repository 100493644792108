// 简体中文
export default {
	language: 'English',
	orderSearch: '订单查询',
	productDetails: '产品详情',
	name: '姓名',
	companyName: '企业名称',
	namePlaceholder: '被保人姓名',
	cardNo: '证件号码',
	assuredCardNo: '被保人证件号码',
	search: '查询',
	searching: '查询中...',
	assuredList: '被保人列表',
	registrationList: '登记列表',
	mobile: '手机号码',
	idCardNo: '身份证号码',
	moreAssured: '还可输入更多被保险人',
	fillName: '请填写姓名',
	fillIdCard: '请填写身份证号码',
	notRequired: '选填',
	addToTheRegistry: '添加至登记列表',
	passportToRegister: '护照登记',
	addToAssured: '添加至被保险人',
	passportInsured: '护照投保',
	agree: '本人已经充分理解并同意',
	insuranceClause: '《保险条款》',
	insuranceDeclaration: '《投保声明》',
	userInfoProtect: '《个人信息保护政策》',
	liabilityContent: '《免责内容》',
	insuredAgrees: '《被保险人同意声明》',
	insurePrompt: '《投保提示》',
	insureHint: '《投保须知》',
	pleaseConfirmTheFollowing: '请确认以下内容',
	iagree: '已阅读并同意',
	insuranceInformation: '投保信息',
	insuredPeriod: '保障期限',
	insuredPeriodP: '请选择保障期限',
	commencementDate: '起保日期',
	commencementDateP: '请选择起保日期',
	terminationDate: '终保日期',
	terminationDateP: '请选择终保日期',
	informationOfPolicyholder: '投保人信息',
	typeOfPolicyholder: '投保人类型',
	idType: '证件类型',
	birthday: '出生日期',
	sex: '性别',
	email: '电子邮箱',
	invoiceTitle: '发票抬头',
	purchaseQuantity: '购买份数',
	otherInfo: '其它信息',
	beneficiaryInformation: '受益人信息',
	statutoryBeneficiary: '法定受益人',
	emergencyContact: '紧急联系人',
	salesVolume: '销量',
	sec: '秒',
	buy: '立即购买',
	addInsured: '添加被保险人',
	passportNo: '护照号码',
	pleaseFillAssuredName: '请填写被保人姓名',
	pleaseFillAssuredPassportNo: '请填写被保人护照号码',
	checkBirthday: '请选择出生日期',
	selectYMD: '选择年月日',
	cancel: '取消',
	ok: '确认',
	selectGender: '请选择性别',
	man: '男',
	female: '女',
	orderPay: '订单支付',
	paySign: '请核对订单，点击下方支付按钮完成支付！',
	orderNo: '订单号',
	goodsName: '产品名称',
	assured: '被保险人',
	insurancePolicy: '保单形式',
	electronicInsurance: '电子保单',
	payment: '支付方式',
	weChatPay: '微信支付',
	pay: '支付',
	paySuccess: '支付成功',
	insuranceCode: '保险编码',
	fillInsuranceCode: '注意字母大小写',
	submit: '提交',
	pFileSearch: '保单查询',
	idCardInsure: '身份证投保',
	isNotIdCardInsure: '非身份证投保',
	policyCode: '保单号',
	policyCodePlaceholder: '请输入保单号',
};
