import Vue from 'vue'
import Vuex from 'vuex'
import insuranceV2 from './modules/insuranceV2'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: '', // userDesc
		productId: '',
		plainId: '',
		billId: '',
		immediateExtendTime: 15, // 即时起保延迟时间
		appId: '', // 公众号唯一标识
		appSecret: '', // 公众号的appsecret
		payCode: '', // 微信用户授权获取的code，用来换取openid，改code5分钟有效
		createOrderRouteName: '', // 创建订单页面路由name

		// 非旅游险变量
		sundryCreateOrderRoutePath: '', // 创建订单页面路由路径
		sundryFormInput: undefined, // 标地信息-配置字段（施工内容选项在这里面）
		priceList: [], // 计划费率
		sundryViewTime: '', // 保障期限
		keepAlivePages: ['productDetail', 'createOrder','orderSearch', 'indexOneselfPay', 'fixedCodeInsUserPay', 'indexInsUserPay', 'accident', 'upfitter', 'construction', 'employer', 'HA_property', 'HA_public', 'jiaoPeiJiGou_insure', 'HT_groupaccident',"healthDetail"], // 缓存页面
		cps_productId: '', //cps产品id
		cps_immediateExtendTime: 15, // 即时起保延迟时间
		cps_plainId: '', //保障计划
		cps_createOrderRouteName: '', // 创建订单页面路由name
		cps_billId: '',
		cps_brindInfo: {}, //品牌信息
		productList: [], //推荐数据
		cps_payCode: '', //微信用户授权获取的code，用来换取openid，改code5分钟有效
		cps_code: JSON.parse(localStorage.getItem('cps_code')) || {},
		cps_keepLiveCom: 'cpsHome,cpsProduct',
		cps_user: '',
		cps_appId: '', // 公众号唯一标识
		cps_appSecret: '', // 公众号的appsecret
		userInfo: {},
		scrollPosition:"",//立牌错误滚动的位置,'clause':勾选条款
	},
	mutations: {
		set_scrollPosition(state,val){
			state.scrollPosition=val
		},
		set_user(state, val) {
			state.user = val;
		},

		set_productId(state, val) {
			state.productId = val;
		},

		set_plainId(state, val) {
			state.plainId = val;
		},

		set_billId(state, val) {
			state.billId = val;
		},

		set_immediateExtendTime(state, val) {
			state.immediateExtendTime = val;
		},

		set_appId(state, val) {
			state.appId = val;
		},

		set_appSecret(state, val) {
			state.appSecret = val;
		},

		set_payCode(state, val) {
			state.payCode = val;
		},

		set_createOrderRouteName(state, val) {
			state.createOrderRouteName = val;
		},

		set_sundryCreateOrderRoutePath(state, val) {
			state.sundryCreateOrderRoutePath = val;
		},

		set_sundryFormInput(state, val) {
			state.sundryFormInput = val;
		},

		set_priceList(state, v) {
			state.priceList = v;
		},

		set_sundryViewTime(state, v) {
			state.sundryViewTime = v;
		},

		// 添加缓存页面
		add_keepAlivePage(state, pageName) {
			if (!state.keepAlivePages.includes(pageName)) {
				state.keepAlivePages.push(pageName);
			}
		},

		// 删除缓存页面
		delete_keepAlivePage(state, pageName) {
			let index = state.keepAlivePages.indexOf(pageName);
			if (index !== -1) {
				state.keepAlivePages.splice(index, 1);
			}
		},
		set_userInfo(state, val) {
			if (!val.id) {
				return;
			}
			state.userInfo = val;
			localStorage.setItem('userInfo', JSON.stringify(val));
		},

		// cps产品ID
		set_cps_productId(state, val) {
			if (val instanceof Object) {
				if (val && Object.hasOwnProperty.call(val, 'product')) {
					let ids = val.product.split(',');
					if (ids.length === 2) {
						(state.cps_productId = +ids[0]), +ids[1];
					}
				}
			}
			state.cps_productId = val;
		},

		set_cps_immediateExtendTime(state, val) {
			state.cps_immediateExtendTime = val;
		},
		//切换保障计划
		set_cps_plainId(state, val) {
			state.cps_plainId = val;
		},
		set_cps_billId(state, val) {
			state.cps_billId = val;
		},

		set_cps_createOrderRouteName(state, val) {
			state.cps_createOrderRouteName = val;
		},
		set_cps_payCode(state, val) {
			state.cps_payCode = val;
		},

		// 品牌所有的信息
		set_cps_brindInfo(state, info) {
			state.cps_brindInfo = info;
			localStorage.setItem('cps_brindInfo', JSON.stringify(info));
		},
		// 存储推荐数据
		set_productList(state, proList) {
			state.productList = proList;
		},
		// 存储code
		set_cps_code(state, obj) {
			if (!obj.code) {
				return;
			}
			state.cps_code = obj;
			localStorage.setItem('cps_code', JSON.stringify(obj));
		},
		set_cps_appId(state, val) {
			state.cps_appId = val;
		},
		set_cps_appSecret(state, val) {
			state.cps_appSecret = val;
		},
		set_cps_user(state, val) {
			state.cps_user = val;
		},

		// 清空缓存页面
		clear_keepAlivePage(state) {
			state.keepAlivePages = [];
		},
		set_cps_keepLiveCom(state, str) {
			state.cps_keepLiveCom = str;
		},
	},
	actions: {
	},
	modules: {
		insuranceV2
	}
})
