// 专属定制信息配置文件，该配置只适用于不修改页面样式的客户
// 该配置文件编辑规则：
// 1. product 字段如果有值，会先匹配用户，再匹配产品id，两者都匹配到的时候，才会使用配置文件参数
// 2. banner banner图文件名，存在时会替换掉匹配的信息
// 3. orderPageName 定制的页面路由的name，一般都是样式有变动，配置该字段的值，要注意去配置路由
// 4. fixedInsureInfo 固定投保人信息，Object，如果不需要配置，fixedInsureInfo字段不可以有。因为代码中用的是Object.assign方法，参数如下：
// {
//     userType: '',        // 投保人类型： 个人;团体 （该参数由接口请求获得，若配置有变，注意修改）
//     userName: '',        // 姓名、企业名称
//     cardType: '',        // 个人证件类型, 赋值时赋label   详情见config/fixedParams.js配置文件下的 cardType
//     groupCardType: '',   // 团体证件类型, 赋值时赋label   详情见config/fixedParams.js配置文件下的 groupCardType
//     cardNum: '',
//     birthday: '',        // 2021/01/01
//     sex: '',             // 男/女
//     phone: '',
//     email: '',
//     buyNum: '1',
// }
export default {
	// 测试环境-平安乐登顶户外运动保障
	// "a161de5b6b9afdc74c96bbc988075974": {
	//     product: "72377",
	//     banner: "chongQingXuanDi.png",
	//     orderPageName: "",
	//     fixedInsureInfo: {
	//         email: '364815552@qq.com'
	//     }
	// },

	// 重庆炫地
	'c7f4c2626b55983a9fb00fdfa18eb728': {
		product: '75587',
		banner: 'chongQingXuanDi.png',
		orderPageName: '',
		fixedInsureInfo: {
			email: '343617545@qq.com',
		},
	},
	// 重庆兄弟
	'c5dc7967a2d3dc0e5baf3f23b73f64a3': {
		product: '',
		banner: 'chongQingBrother.png',
		orderPageName: 'createOrderFixed',
	},
	// 宜宾丛林越野
	'431644fb448705116f106e12b98d4d78': {
		product: '75587',
		banner: 'yiBinCongLinYueYe.png',
	},
	// 温江标地
	'1ce65dbeeb4aba373a94e64afb65bdac': {
		product: '',
		banner: 'wenJiangBiaoDi.png',
	},
	'fe18ce2c26d4de5e4b97113fe5f9013d': {
		banner: 'wenJiangBiaoDi.png',
	},
	'f921aa7ea868d6c788f6f52ee95747a1': {
		banner: 'wenJiangBiaoDi.png',
	},

	// 贵州省遵义市坊安陵户外拓展有限公司
	'e71e2e251c91e7134f7a1a911c9cec67': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '贵州省遵义市坊安陵户外拓展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91520321MAALUFPX8M',
			phone: '18586945563',
			email: '754066124@qq.com',
		},
	},

	// 珠海市越道商贸有限公司
	'f87e5b975a20a370bc2254f88c66266f': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '珠海市越道商贸有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440402MA557NKA0T',
			phone: '13924945307',
			email: '844769092@qq.com',
		},
	},

	// 东莞市飞野户外拓展有限公司
	'23b1df73398befe7bbca1713b4eea606': {
		product: '45567',
		fixedInsureInfo: {
			userType: '团体',
			userName: '飞野户外拓展公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441900MA567X819D',
			phone: '18898743687',
			email: '921652955@qq.com',
		},
	},

	// 东莞市蝴蝶谷户外拓展有限公司
	'18f077e567a195d32b8cc187646fb719': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '东莞市蝴蝶谷户外拓展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441900MA55PEDT54',
			phone: '18588106805',
			email: '755673120@qq.com',
		},
	},

	// 东莞水濂山体育文化发展有限公司
	'3468eed0719b9bf9f43221c8460dbfd5': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '东莞水濂山体育文化发展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441900MA57129C9F',
			phone: '13267327688',
			email: '530926706@qq.com',
		},
	},

	// 贵州汇天正旅游服务有限工司
	'cb481f8a49e246317341dfa3e5fec079': {
		product: '75587',
		banner: 'guiZhouHuiTianZhengLv.png',
	},

	// 厦门晓蜗轮旅游开发有限公司
	'41f55d629a66b8b21c7d502bbb650b80': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '厦门晓蜗轮旅游开发有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91350211MA8TRK6G9M',
			phone: '13395019930',
			email: '923443948@qq.com',
		},
	},

	// 钟远辉
	'a97159918ec9ca58be3227143de900e8': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '钟远辉',
			cardType: '身份证',
			cardNum: '360726199002076012',
			birthday: '1990/02/07',
			sex: '男',
			phone: '15507052885',
			email: '285066426@qq.com',
		},
	},

	// 彗行户外运动（惠州）有限公司
	'442c27a4996fa6aa4050d86530670752': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '彗行户外运动（惠州）有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441303MA54PHL81C',
			phone: '18933532455',
			email: '30305347@qq.com',
		},
	},

	// 万忠
	'fb427cba6c71974c8fe864bf71e20a48': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '万忠',
			cardType: '身份证',
			cardNum: '50023319930322865X',
			birthday: '1993/03/22',
			sex: '男',
			phone: '13826938448',
			email: '694491124@qq.com',
		},
	},

	// 惠州市长鑫龙户外有限公司
	'e1c7a42bf416f2044b5931aa5832c254': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '惠州市长鑫龙户外有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441303MA547K4915',
			phone: '15012929569',
			email: '56982627@qq.com',
		},
	},

	// 巴中市炫酷生态农业观光旅游有限公司
	'750c7a8fc1c6f9dd3acbb02a70513724': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '巴中市炫酷生态农业观光旅游有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91511902MA6BP3HU6C',
			phone: '13688362434',
			email: '466597587@qq.com',
		},
	},

	// 邵阳市大新休闲农业有限公司
	'e5d8753b53aa39f2b3c5c4ecc7cc994a': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '邵阳市大新休闲农业有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91430522MA4R6C8642',
			phone: '13823338739',
		},
	},

	// 贵州贵阳山行者休闲娱乐有限责任公司花溪分公司
	'a97edf9fbcee61ba9ff671c53e2313a0': {
		banner: 'guiZhouShanYangXingZhe.png',
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '贵州贵阳山行者休闲娱乐有限责任公司花溪分公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91520111MAALUN5L4J',
			phone: '17385526791',
			email: '942506141qq@com',
		},
	},

	// 兰陵全地形户外运动有限公司
	'26bd5f674aefa5e9a49d09f2848801c8': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '兰陵全地形户外运动有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91371324MA94CM6G1Q',
			phone: '15065924333',
			email: '451048785@qq.com',
		},
	},

	// 重庆小波哥户外拓展有限公司
	'e760eb08c53ee7331c163ca0703669a5': {
		product: '75587',
		banner: 'xiaoBoGe.png',
		fixedInsureInfo: {
			userType: '团体',
			userName: '重庆小波哥户外拓展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91500105MA5YYR8072',
			phone: '15086745901',
		},
	},

	// 李贵浩
	'9b2f794527138afec389a6c10758f41a': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州市牙小兵户外运动有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440183MA59CCAR7G',
			phone: '13118618118',
			email: '824551363@qq.com',
		},
	},

	// 蔡正鑫
	'b0edae350f24711d8b97d1446082f0fe': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州楚兴丛林越野俱乐部有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440101MA9XP6FCX1',
			phone: '18002272925',
			email: '931264395@qq.com',
		},
	},

	// 李琴
	'29173ea346286bca46f509c3a5df9764': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '北湖区仙岭湖丛林越野俱乐部',
			groupCardType: '统一社会信用代码',
			cardNum: '92431002MA4T1WMB3X',
			phone: '18873565377',
			email: '58236755@qq.com',
		},
	},

	// 刘钢
	'9a9a6d8d34ec8706ec3bee436c08879d': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '邵阳市新华山地户外运动俱乐部有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91430500MA4REN6Y4R',
			phone: '18173967676',
			email: '274640273@qq.com',
		},
	},

	// 郑飞
	'9d9545cda55ae1595087c8372b570721': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '郑飞',
			cardType: '身份证',
			cardNum: '610121198304081098',
			birthday: '1983/04/08',
			sex: '男',
			phone: '13572995518',
		},
	},

	// 景德镇源通文化旅游产业有限公司
	'59a2c5a9b24068944681e75cfbc61ccb': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '景德镇源通文化旅游产业有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91360222MA3ACBWN94',
			phone: '18307054961',
			email: '1429030964@qq.com',
		},
	},

	// 潮州市野狼体育用品有限公司
	'deb2acbb48e9c02b22908bbc6156081a': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '潮州市野狼体育用品有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91445102MA54L0XT1Q',
			phone: '15913001555',
		},
	},

	// 徐岩
	'a1fb05e0ecf1d7e99380fb92750a60e9': {
		product: '75587',
		banner: 'xuYan.png',
		fixedInsureInfo: {
			userType: '团体',
			userName: '承德圣骑健身休闲服务有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91130823MA0GCXJC6A',
			phone: '18831441344',
			email: '864848627@qq.com',
		},
	},

	// 山竹越野俱乐部
	'fea49edff61991fb5653cfbe3e164767': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},

	// 牙小兵
	'faffc68947e24cdd6ca6e510eb5f3962': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州市牙小兵户外运动有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440183MA59CCAR7G',
			phone: '13118618118',
			email: '824551363@qq.com',
		},
	},

	// 阳朔三木旅游开发有限公司
	'a38d867f26f17e231678ed4d117e4aeb': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '阳朔三木旅游开发有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91450321MA5NRHYW9A',
			phone: '15678488888',
		},
	},

	// 旺吾户外越野卡丁车基地
	'13c667fea6edadfa689a7710036217d2': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '俞文广',
			cardType: '身份证',
			cardNum: '330722198004107913',
			birthday: '1980/04/10',
			sex: '男',
			phone: '13857958388',
			email: 'ywg7773@163.com',
		},
	},

	// 广州天哲教育科技有限公司
	'6046622ced045d064f28662279faaf39': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州天哲教育科技有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440106743555306G',
			phone: '15915848848',
			email: '1204614683@qq.com',
		},
	},

	// 韶关百石谷越野活动有限公司
	'211af16fc30cb8ed6b9cb1fac9b50791': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '韶关百石谷越野活动有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440232MA55XEH903',
			phone: '13827991399',
		},
	},

	// 山西晋情玩旅游发展有限公司
	'3a1f1f2dbe63c52c49c511cf9d99e8f9': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '山西晋情玩旅游发展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91140702MA0LCWF55K',
			phone: '17696090423',
		},
	},

	// 绵竹市自游人户外拓展俱乐部
	'a98ff671fe4e7cdd7701bc7add296543': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '绵竹市自游人户外拓展俱乐部',
			groupCardType: '统一社会信用代码',
			cardNum: '91510683MA64LNA533',
			phone: '18281006645',
			email: '978432811@qq.com',
		},
	},

	// 惠州姚姚体育文化有限公司
	'8b398553f8101736407517a518e1971a': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '惠州姚姚体育文化有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91441300MA559GG64G',
			phone: '13411256256',
			email: '495535626@qq.com',
		},
	},

	// 李成星
	'7ed9ddf52c255af11c52ecc89389af10': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '贺州市平桂区纵横丛林越野卡丁车俱乐部中心',
			groupCardType: '统一社会信用代码',
			cardNum: '92451103MA5QDAUT6G',
			phone: '17777448882',
			email: '389330811@qq.com',
		},
	},

	// 麦田丛林越野
	'e24debc72424185a2ea7527e18a28af5': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州麦田丛林越野户外体育有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440101MA9XUWGP06',
			phone: '13433281153',
		},
	},

	// 张春振
	'4518a7b7efc866a72caff8c23d66a9e0': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '广州正果丛林越野俱乐部有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91440101MA9W2FU803',
			phone: '13802621161',
		},
	},

	// 成都天越户外运动有限公司
	'322994225d186831b912de8da1a4d589': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '成都天越户外运动有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91510114MAACJ0DW46',
			phone: '17726332706',
		},
	},

	// 李民民
	'b43d95c407e65077912b2f09bb168b72': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '新余圆梦园生态庄园有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91360500MA3884JL70',
			phone: '13879010692',
		},
	},

	// 衡阳飞驰户外拓展有限公司
	'b1ea3fe4b858d1f93fb35327051c6ee4': {
		product: '75587',
		fixedInsureInfo: {
			userType: '团体',
			userName: '衡阳飞驰户外拓展有限公司',
			groupCardType: '统一社会信用代码',
			cardNum: '91430406MA4T9XT55L',
			phone: '18674717482',
		},
	},

	// 刘纪阳
	'0246a571b1db74f5dfc00725a0a4b1a3': {
		product: '75587',
		banner: 'liuJiYang.png',
	},

	// 山竹越野俱乐部-张海勇东莞山竹
	'ca318021993bc3f68a14d1ef169b30fd': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
	// 山竹越野俱乐部-李浪杭州浪公子
	'3d4b0829a2c18a9d014304d6f3ecdddc': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
	// 山竹越野俱乐部-黄燕大连战狼
	'2c7e6c92740cabf3729a0615ab714a98': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
	// 山竹越野俱乐部-陈耀鑫黄江山林
	'4bed3db147f32f1ba4a6e44573aff880': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
	// 山竹越野俱乐部-小文子惠州山竹
	'8d7dba7c76cbd71c54f8aa1275a94cca': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
	// 山竹越野俱乐部-张韶炼广州山竹
	'2c2148461d0f10586eb93ad4f4cfc000': {
		product: '75587',
		fixedInsureInfo: {
			userType: '个人',
			userName: '徐勇',
			cardType: '身份证',
			cardNum: '430902199209169118',
			birthday: '1992/09/16',
			sex: '男',
			phone: '13728480915',
		},
	},
};
