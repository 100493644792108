<template>
	<div id="app">
		<div id="nav"></div>
		<!-- <keep-alive :include="['productDetail', 'createOrder', 'createOrderFixed']"> -->
		<keep-alive :include="[]">
			<router-view />
		</keep-alive>
	</div>
</template>

<script>
import { getBillInfo } from '@/request/api';
import replaceMap from '@/config/replaceMap';

export default {
	created() {
		this.getUrlParams();
	},
	beforeCreate() {
		// 这里处理新商品替换就商品
		if (window.location.search.includes('product=')) {
			let id = window.location.search.split('product=')[1];
			let keys = Object.keys(replaceMap);
			if (keys.includes(id)) {
				window.location.href = window.location.href.replace(`product=${id}`, `product=${replaceMap[id]}`);
			}
		}
	},
	methods: {
		// 获取url中的参数
		getUrlParams() {
			// H5投保页面，需要执行该方法,过滤掉其它页面
			let includePath = ['insure', 'fixedCodeInsure', 'sundry', 'liability', 'cps'];
			let pathList = window.location.pathname.split('/');
			let go = false;

			for (const path of pathList) {
				if (includePath.includes(path)) {
					go = true;
					break;
				}
			}

			if (!go) {
				return;
			}

			// H5投保页面跳转判断逻辑
			if (window.location.href.includes('orderPayRedict')) {
				// 获取code重定向回来
				let stateInfo = JSON.parse(localStorage.stateInfo);
				// 设置vuex store
				this.$store.commit('set_user', stateInfo.user);
				this.$store.commit('set_productId', stateInfo.productId);
				this.$store.commit('set_plainId', stateInfo.plainId);
				this.$store.commit('set_billId', stateInfo.billId);
				this.$store.commit('set_appId', stateInfo.appId);
				this.$store.commit('set_appSecret', stateInfo.appSecret);
				this.$store.commit('set_payCode', this.$route.query.code);

				// 回到支付页
				this.$router.push({
					name: 'orderPay',
					params: { isRedict: true },
				});
			} else if (window.location.href.includes('cpsOrderPayRedict')) {
				// 获取code重定向回来
				let cps_stateInfo = JSON.parse(localStorage.cps_stateInfo);
				// 设置vuex store
				this.$store.commit('set_cps_user', cps_stateInfo.user);
				this.$store.commit('set_cps_productId', cps_stateInfo.productId);
				this.$store.commit('set_cps_plainId', cps_stateInfo.plainId);
				this.$store.commit('set_cps_billId', cps_stateInfo.billId);
				this.$store.commit('set_cps_appId', cps_stateInfo.appId);
				this.$store.commit('set_cps_appSecret', cps_stateInfo.appSecret);
				this.$store.commit('set_cps_payCode', this.$route.query.code);

				// 回到支付页
				this.$router.push({
					name: 'cpsOrderPay',
					params: { isRedict: true },
				});
			} else if (window.location.href.includes('fixedCodeOrderPayRedict')) {
				// 立牌投保重定向来的
				let stateInfo = JSON.parse(localStorage.stateInfo);
				// 设置vuex store
				this.$store.commit('set_user', stateInfo.user);
				this.$store.commit('set_productId', stateInfo.productId);
				this.$store.commit('set_plainId', stateInfo.plainId);
				this.$store.commit('set_billId', stateInfo.billId);
				this.$store.commit('set_appId', stateInfo.appId);
				this.$store.commit('set_appSecret', stateInfo.appSecret);
				this.$store.commit('set_payCode', this.$route.query.code);

				// 回到支付页
				this.$router.push({
					name: 'fixedCodeOrderPay',
					params: { isRedict: true },
				});
			} else if (window.location.href.includes('paySuccessAfterPay')) {
				// H5支付模式支付成功
				// 支付成功，重定向来的，应该去往支付成功页面
				// TODO:此处应该去检查订单支付情况
				// paySuccessAfterPay
				const billId=this.$store.state.billId||localStorage.getItem("success_id")
				getBillInfo(billId).then(res => {
					if (res.billInfo.billState == 3 || res.billInfo.billState == 4) {
						localStorage.removeItem("success_id")
						this.$router.push({
							name: 'insurePaySuccess',
							params: { protectBillUrl: undefined },
						});
					}
				});
			} else {
				// PC端扫码过来的
				if (window.location.href.includes('product=')) {
					localStorage.homeUrl = window.location.href;
					let paramsList = window.location.search
						.slice(1)
						.split('&')
						.map(item => {
							let keyVal = item.split('=');
							return {
								key: keyVal[0],
								val: keyVal[1],
							};
						});
					let params = {};
					paramsList.forEach(item => {
						params[item.key] = item.val;
					});

					this.$store.commit('set_user', params.user);
					this.$store.commit('set_productId', params.product);
				} else if (window.location.href.includes('qrcode=')) {
					// 立牌扫码过来的
					localStorage.setItem('homeUrl', window.location.href);
					let paramsList = window.location.search.split(/=|&/);
					this.userDesc = paramsList[1];
					this.$store.commit('set_user', paramsList[1]);
				} else if (window.location.href.includes('/sundry')) {
					// 非旅游险
					localStorage.setItem('homeUrl', window.location.href);
				} else if (window.location.href.includes('/cps/')) {
					// cps
					localStorage.setItem('homeUrl', window.location.href);
				} else if (window.location.href.includes('health/paySuccess')) {
					localStorage.setItem('homeUrl', window.location.href);
				} else {
					// 页面刷新过来的
					let homeUrl = localStorage.homeUrl;
					if (homeUrl && homeUrl !== 'undefined' && (homeUrl.includes('product') || homeUrl.includes('qrcode') || homeUrl.includes('/sundry'))) {
						window.location.href = localStorage.homeUrl;
					}
				}
			}
		},
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #2c3e50;
}
</style>
