import Vue from 'vue';
import VueI18n from 'vue-i18n';

// 本地多语言
import zhCN from './lang/zh-CN';
import en from './lang/en';

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'zhCN',
	messages: {
		zhCN,
		en,
	},
});
// new Vue({ i18n }).$mount('#app')
// locale.i18n((key, value) => i18n.t(key, value))

export default i18n;
