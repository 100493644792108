// 英文
export default {
	language: '中文',
	orderSearch: 'order inquiry',
	productDetails: 'product details',
	name: 'Full name',
	companyName: 'company name',
	namePlaceholder: 'name of insured person',
	cardNo: 'ID Number',
	assuredCardNo: 'Id number of insured person',
	search: 'query',
	searching: 'query ...',
	assuredList: 'List of insured',
	registrationList: 'Registration list',
	mobile: 'Phone No.',
	idCardNo: 'ID card No.',
	moreAssured: 'More insured',
	fillName: 'Fill in the name',
	fillIdCard: 'Fill in ID Card No.',
	notRequired: 'Not required',
	addToTheRegistry: 'Add to the registry',
	passportToRegister: 'Passport to register',
	addToAssured: 'Insured list',
	passportInsured: 'Passport is insured',
	agree: 'I fully understand and agree',
	insuranceClause: '《Insurance clause》',
	insuranceDeclaration: '《Insurance declaration》',
	userInfoProtect: '《Personal Information protection policy》',
	liabilityContent: '《Liability content》',
	insuredAgrees: '《The insured agrees to declare》',
	insurePrompt: '《Insure prompt》',
	pleaseConfirmTheFollowing: 'Please confirm the following',
	iagree: 'Agree',
	insuranceInformation: 'Insurance information',
	insuredPeriod: 'Insured Period',
	insuredPeriodP: 'Insured Period',
	commencementDate: 'Commencement Date',
	commencementDateP: 'Commencement Date',
	terminationDate: 'Termination Date',
	terminationDateP: 'Termination Date',
	informationOfPolicyholder: 'Information of policyholder',
	typeOfPolicyholder: 'Type of Policyholder',
	idType: 'ID Type',
	birthday: 'Date of birth',
	sex: 'Sexual',
	email: 'Email',
	invoiceTitle: 'invoice title',
	purchaseQuantity: 'purchase quantity',
	otherInfo: 'other information',
	beneficiaryInformation: 'Beneficiary information',
	statutoryBeneficiary: 'statutory beneficiary',
	emergencyContact: 'emergency contact',
	salesVolume: 'sales volume',
	sec: 'sec',
	buy: 'submit',
	addInsured: 'Add insured',
	passportNo: 'Passport No.',
	pleaseFillAssuredName: 'Fill in the name of insured person',
	pleaseFillAssuredPassportNo: 'Fill in the Passport No.',
	checkBirthday: 'Choose your date of birth',
	selectYMD: 'Select year month day',
	cancel: 'Cancel',
	ok: 'OK',
	selectGender: 'Select',
	man: 'male',
	female: 'female',
	orderPay: 'Order payment',
	paySign: 'Please check the order and make payment !',
	orderNo: 'The order no.',
	goodsName: 'product name',
	assured: 'insured',
	insurancePolicy: 'Insurance policy',
	electronicInsurance: 'electronic insurance',
	payment: 'Payment',
	weChatPay: 'WeChat Pay',
	pay: 'Pay',
	paySuccess: 'success',
	insuranceCode: 'Insurance CODE',
	fillInsuranceCode: 'Pay attention to letter case',
	submit: 'submit',
	pFileSearch: 'The policy query',
	idCardInsure: 'ID card',
	isNotIdCardInsure: 'other',
	policyCode: 'policyNo',
	policyCodePlaceholder: 'Please enter the policy number',
};
