import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/main.css';
import './assets/fontIcon/iconfont.css';
import base from './assets/js/base';
Vue.prototype.$base = base;

import khs from './components/khs'
window.khs = khs

// 引入全局组件
import '@/components';

Vue.config.productionTip = false;

// 多语言
import i18n from './language';

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
