import Vue from 'vue';
import { Toast ,Dialog} from 'vant';
Vue.use(Toast);
Vue.use(Dialog);


import axios from 'axios';
import qs from 'qs';
axios.defaults.withCredentials = true;
Vue.prototype.axios = axios;

export function get(url, data = null, responseType = 'json') {
	return axios.get(url, {
		params: data,
		paramsSerializer: params => {
			return qs.stringify(params, { indices: false });
		},
		responseType: responseType,
	});
}

export function post(url, data = null, responseType = 'json',) {
	return axios.post(url, data, { responseType: responseType });
}

export function postFormData(url, data={}, responseType = 'json') {
	let fileFormData = new FormData();
	for(let i in data){
		fileFormData.append(i, data[i]);
	}
	return axios.post(url,fileFormData,{ responseType: responseType, headers: { 'Content-Type': 'multipart/form-data' } })
  }

export function getUrl(url, params) {
	if (Array.isArray(params)) {
		params.forEach(item => {
			url += `/${item}`;
		});
	} else {
		url += `/${params}`;
	}
	return axios.get(url);
}

export function postParams(url, data) {
	return axios({
		method: 'post',
		url: url,
		params: data,
	});
}

// params可以是简单数据类型类型，也可以是数组类型
export function postUrl(url, params = '', data = null, responseType = 'json') {
	if (Array.isArray(params)) {
		params.forEach(item => {
			url += `/${item}`;
		});
	} else {
		url += `/${params}`;
	}

	if (data) {
		let dataParams = qs.stringify(data);
		url += `?${dataParams}`;
	}
	return axios.post(url, null, { responseType: responseType });
}

// 并发请求
export function all() {
	return axios.all;
}

export function spread() {
	return axios.spread;
}

//响应拦截
axios.interceptors.response.use(
	function (response) {
		if(response.request.responseURL.includes('insurance/api/insPayUrl/getInsPayUrl')){
			return Promise.resolve(response.data)
		}
		if(response.request.responseURL.includes('insurance/api/v2/insure/insuredOrder')){
			return Promise.resolve(response.data)
		}
		if(response.request.responseURL.includes('insurance/api/insFileComponent/insFileDownload')){
			return Promise.resolve(response.data)
		}
	
		if(response.request.responseURL.indexOf("openApi")!==-1){
			if(response.data.code===0){
				return Promise.resolve(response.data)
			}else{
				Toast.fail(response.data.message)
				return Promise.reject(response.data);
			}
		}
       // 支付平台
		if (Object.hasOwnProperty.call(response.data, 'code') && response.data.code === 1) {
			return Promise.resolve(response.data.outData);
		} else if (Object.hasOwnProperty.call(response.data, 'success') && response.data.success) {
			return Promise.resolve(response.data.data);
		} else {
			
			if (!response.request.responseURL.includes('checkLogin')) {
				if(response.data.message.indexOf("InsuredMaxNumberError:")!==-1){
					Dialog.alert({title: '错误提示',message:response.data.message.replace(/InsuredMaxNumberError:/, "")});
				}else{
					Toast.fail(response.data.message);
				}
			}
			console.log(response.data);
			return Promise.reject(response.data);
		}

		// if (response.data.code === 1 || response.data.success) {
		//     return Promise.resolve(response.data.outData)
		// }else{
		//     Toast.fail(response.data.message);
		//     console.log(response.data)
		//     return Promise.reject(response.data)
		// }
	},
	function (error) {
		return Promise.reject(error);
	},
);
