<style lang="scss" scoped>
.price_buy_btn {
	.cont {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1.1rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #eeeeee;
		font-size: 0;
		z-index: 3000;
		.price {
			background-color: #fff;
			width: 3rem;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.text {
				font-size: 0.24rem;
				color: $font_color_main;
				line-height: 0.24rem;
				margin-right: 0.08rem;
			}
			.icon {
				font-size: 0.24rem;
				color: #ff5702;
			}
			.num {
				font-size: 0.36rem;
				color: #ff5702;
				line-height: 0.4rem;
			}
			.price_content {
				display: flex;
				align-items: baseline;
			}
		}
		.btn {
			height: 100%;
			flex: 1;
			font-size: 0.28rem;
			line-height: 1.1rem;
			text-align: center;
			color: #fff;
			background-image: url(../assets/icon/buy.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}
	.back_dom {
		height: 100%;
		height: 1.1rem;
	}
}
</style>

<template>
	<div class="price_buy_btn">
		<div class="cont">
			<div class="price">
				<div class="price_content">
					<span class="text">价格</span>
					<span class="icon">￥</span>
					<span class="num" v-text="floatPrice"></span>
					<span v-if="isword" class="icon">起</span>
				</div>
			</div>
			<div class="btn" @click="buy">
				<slot>立即购买</slot>
			</div>
		</div>
		<div class="back_dom"></div>
	</div>
</template>

<script>
export default {
	name: 'priceBuyBtn',
	props: {
		price: {
			type: [Number, String],
			required: false,
			default: '0',
		},

		// 是否需要格式化
		format: {
			type: Boolean,
			required: false,
			default: true,
		},

		// 是否需要加个起字
		isword: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		floatPrice() {
			if (this.format) {
				return this.$base.floatPrice(this.price);
			} else {
				return this.price;
			}
		},
	},
	data() {
		return {};
	},
	methods: {
		buy() {
			this.$emit('buy', this.price);
		},
	},
};
</script>
