import { post } from "./http";
//获取产品配置
export function http_getProductConfig(data) {
    return post("/insurance/api/v2/insure/getProductConfig", data);
}
//获取计划配置
export function http_getProductComboConfig(data) {
    return post("/insurance/api/v2/insure/getProductComboConfig", data);
}
//数据字典
export function http_getProductDataDictListByCode(data) {
    return post(`/insurance/api/v2/insure/getProductDataDictListByCode/${data}`);
}
//获取系统职业
export function http_getProfessionCodeList(data) {
    return post(`/insurance/api/v2/insure/getProfessionCodeList`,data);
}
//投保下单
export function http_insuredOrder(data) {
    return post(`/insurance/api/v2/insure/insuredOrder`,data);
}

//获取代码配置
export function http_getCodeList() {
	return post('/insurance/api/v2/insure/getMechanismCommonCodeList');
}
// 获取代码分组
export function http_getCodeCategory(code = 'codeCategory') {
	return post(`/insurance/api/v2/insure/getProductDataDictListByCode/${code}`);
}
/**
 * 根据保司机构code获取省市区
 * @param {string} insurerCode 保司机构code
 * @returns Promise
 */

export function http_getAreaList(insurerCode) {
	return post(`/insurance/api/v2/insure/getInsProductAreaCodeList?insurerCode=${insurerCode}`);
}
// 获取系统国籍
export function http_getCountryList() {
	return post('/insurance/api/v2/insure/getNationalityCodeList');
}

//获取关联产品配置
export function http_getProductCorrelation(proId) {
	return post(`/insurance/api/product/getProductCorrelation/${proId}`);
}