import {http_getProfessionCodeList,http_getAreaList,http_getCountryList,http_getCodeList, http_getCodeCategory} from "../../request/insureV2"
const insuranceV2={
    state:{
        protectAgeMin:0,//最小投保年龄
        protectAgeMax:99,//最大投保年龄
        priceId: '',
		price:0,
		allPrice: '',
		showCommon: false, //是否显示同投保人按钮
		insProductPrice2VoList: {}, //计划选择
		allForm: {}, //存储所有的表单数据
		allFormId:{},//存储所有的表单数据,
		formOptions:{},//表单options
		careerList:[],//保存职业
		areaList:[],//获取地区省市区
		countryList:[],//获取国籍或旅游目的地
		codeCategory:[],
		codeList:[],
		selComboIdPriceList:[],
		days:0,//计算天数
		startTime:"",
		preventDuty:"",//免责声明
		hint: '', //投保须知
    },
    mutations:{
		setPreventDutyV2(state,type){
			state.preventDuty=type
		},
		setHint(state, type) {
			state.hint = type;
		},
		setDays(state,type){
			state.days=type
		},
		setStartTime(state,type){
			state.startTime=type
		},
        set_protectAgeMin(state,type){
            state.protectAgeMin=type
        },
        set_protectAgeMax(state,type){
            state.protectAgeMax=type
        },
		setPrice(state,type){
			state.price=type
		},
        setPriceId(state, type) {
			state.priceId = type;
		},
		setAllPrice(state, type) {
			state.allPrice = type;
		},
		setShowCommon(state, type) {
			state.showCommon = type;
		},
		setInsProductPrice2VoList(state, type) {
			state.insProductPrice2VoList = type;
		},
		setAllForm(state, type) {
			state.allForm = { ...state.allForm, ...type };
		},
		setAllFormId(state, type) {
			state.allFormId = { ...state.allFormId, ...type };
		},
		setFormOptions(state,type){
			state.formOptions = { ...state.formOptions, ...type };
		},
		setCareer(state,type){
			state.careerList=type
		},
		setArea(state,type){
			state.areaList=type
		},
		setCountry(state,type){
			state.countryList=type
		},
		setCodeList(state,type){
			state.codeList=type
		},
		setCodeCategory(state,type){
			state.codeCategory=type
		},
		setReset(state) {
			state.price = 0;
			state.allPrice = '';
			state.insProductPrice2VoList = {};
		},
		setSelComboIdPriceList(state){
			state.selComboIdPriceList=state
		}
	
    },
	actions: {
		// 获取职业信息
		async getCareer({commit}) {
			const res=await http_getProfessionCodeList()
			commit('setCareer', res);
		},
		// 获取省市区地址
		async getArea({commit},paylod){
		    const res = await http_getAreaList(paylod)
			commit('setArea',res[0].children)
		},
		// 获取国籍或旅行目的地
		async getCountry({commit}){
			const res=await http_getCountryList()
			commit('setCountry',res)
		},
		// 获取代码分组
		async getCodeCategory({commit}) {
			const res = await http_getCodeCategory();
			commit("setCodeCategory",res)
		},
		// 获取代码配置
		async getCode({commit}) {
			const res = await http_getCodeList();
			commit("setCodeList",res)
		},

	}
}
export default insuranceV2