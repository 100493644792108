<style lang="scss" scoped>
.active_title {
	display: flex;
	align-items: center;
	font-size: 0;
	padding: 0.2rem 0.3rem;
	.default_icon {
		width: 0.07rem;
		height: 0.28rem;
		border-radius: 0.03rem;
		background-color: $color_main;
		margin-right: 0.14rem;
	}
	.text {
		font-size: 0.28rem;
		line-height: 0.28rem;
		color: $color_main;
		display: flex;
		align-items: center;
	}
}
</style>

<template>
	<div class="active_title">
		<slot name="icon">
			<span class="default_icon"></span>
		</slot>
		<div class="text">
			<slot>标题内容</slot>
			<slot name="custom"></slot>
		</div>
		<slot name="cont" />
	</div>
</template>

<script>
export default {
	name: 'avtiveTitle',
	data() {
		return {};
	},
};
</script>
