// 基础方法
import {http_insTranslate} from "../../request/common"
import moment from 'moment/moment';
import store from "@/store/index"

export default {
	// 格式化日期
	dateFormater(date) {
		let year = date.getFullYear(),
			month = String(date.getMonth() + 1).padStart(2, '0'),
			day = String(date.getDate()).padStart(2, '0');
		return `${year}/${month}/${day}`;
	},

	// 获取时间对象
	getDateObject(timeString) {
		let result = new Date();
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				result = new Date(timeString);
			} else if (typeof timeString === 'string') {
				result = new Date(timeString.replace(/-/g, '/'));
			}
		}
		return result;
	},

	/**
	 * 提交日期格式化
	 * @param {String} dateStr 日期字符串
	 * @param {Number} hmsType 时间格式：0-无时分秒，1-时分秒为00:00:00，2-时分秒为23:59:59
	 */
	submitDateFormatter(dateStr, hmsType = 0) {
		if (!dateStr) {
			return '';
		}
		let date = new Date(dateStr);
		let year = date.getFullYear(),
			month = String(date.getMonth() + 1).padStart(2, '0'),
			day = String(date.getDate()).padStart(2, '0');
		if (hmsType) {
			let hms = hmsType === 1 ? '00:00:00' : '23:59:59';
			return `${year}-${month}-${day} ${hms}`;
		}

		return `${year}-${month}-${day}`;
	},

	/**
	 * 获取日期时间
	 * @param {String} timeString 时间字符串
	 * @returns yy-MM-dd hh:mm:ss
	 */
	getDateTime(timeString) {
		let time;
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				time = new Date(timeString);
			} else if (typeof timeString === 'string') {
				time = new Date(timeString.replace(/-/g, '/'));
			}
		} else {
			time = new Date();
		}
		let h = String(time.getHours()).padStart(2, '0'),
			m = String(time.getMinutes()).padStart(2, '0'),
			s = String(time.getSeconds()).padStart(2, '0');
		let d = String(time.getDate()).padStart(2, '0'),
			M = String(time.getMonth() + 1).padStart(2, '0'),
			y = String(time.getFullYear());
		return `${y}-${M}-${d} ${h}:${m}:${s}`;
	},

	/**
	 * 获取日期
	 * @param {String} timeString 时间字符串
	 * @returns yy-MM-dd hh:mm:ss
	 */
	getDate(timeString) {
		let time;
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				time = new Date(timeString);
			} else if (typeof timeString === 'string') {
				time = new Date(timeString.replace(/-/g, '/'));
			}
		} else {
			time = new Date();
		}
		let d = String(time.getDate()).padStart(2, '0'),
			M = String(time.getMonth() + 1).padStart(2, '0'),
			y = String(time.getFullYear());
		return `${y}-${M}-${d}`;
	},

	/**
	 * 获取日期
	 * @param {String} timeString 时间字符串
	 * @returns yy-MM-dd hh:mm:ss
	 */
	getDateFormate(timeString) {
		let time;
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				time = new Date(timeString);
			} else if (typeof timeString === 'string') {
				time = new Date(timeString.replace(/-/g, '/'));
			}
		} else {
			time = new Date();
		}
		let d = String(time.getDate()).padStart(2, '0'),
			M = String(time.getMonth() + 1).padStart(2, '0'),
			y = String(time.getFullYear());
		return `${y}/${M}/${d}`;
	},

	/**
	 * 获取日期
	 * @param {String} timeString 时间字符串
	 * @returns yy年 MM月 dd日
	 */
	getDateChinese(timeString) {
		let time;
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				time = new Date(timeString);
			} else if (typeof timeString === 'string') {
				time = new Date(timeString.replace(/-/g, '/'));
			}
		} else {
			time = new Date();
		}
		let d = String(time.getDate()).padStart(2, '0'),
			M = String(time.getMonth() + 1).padStart(2, '0'),
			y = String(time.getFullYear());
		return `${y}年 ${M}月 ${d}日`;
	},

	/**
	 * 获取时间
	 * @param {String} timeString 时间字符串
	 * @returns hh:mm:ss
	 */
	getTime(timeString) {
		let time;
		if (timeString) {
			if (typeof timeString === 'number' || typeof timeString === 'object') {
				time = new Date(timeString);
			} else if (typeof timeString === 'string') {
				time = new Date(timeString.replace(/-/g, '/'));
			}
		} else {
			time = new Date();
		}
		let h = String(time.getHours()).padStart(2, '0'),
			m = String(time.getMinutes()).padStart(2, '0'),
			s = String(time.getSeconds()).padStart(2, '0');
		return `${h}:${m}:${s}`;
	},

	// 金额格式化
	floatPrice(price) {
		return Number(price).toFixed(2);
	},

	/**
	 * 根据身份证号码计算年龄（向下取整）、出生日期、性别。此处不做身份证格式校验
	 * @param {string} idNumber 身份证号码
	 */
	getInfoFromIdNumber(idNumber) {
		let result = {
			age: '',
			birthday: '',
			sex: '',
		};

		// 年龄
		let idDate = idNumber.slice(6, 14);
		let date = `${idDate.slice(0, 4)}-${idDate.slice(4, 6)}-${idDate.slice(6)}`;
		let birthday = new Date(date).getTime();
		let now = Date.now();
		result.age = Math.floor((now - birthday) / (1000 * 60 * 60 * 24 * 365));

		// 出生日期
		result.birthday = `${idNumber.slice(6, 10)}/${idNumber.slice(10, 12)}/${idNumber.slice(12, 14)}`;

		// 性别
		let genderNum = Number(idNumber[16]);
		result.sex = genderNum % 2 === 0 ? '女' : '男';

		return result;
	},

	/**
	 * 检测当前环境是否为移动端
	 * @returns Boolean
	 */
	isMobile() {
		var userAgentInfo = navigator.userAgent;
		var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
		var flag = true;
		for (var v = 0; v < Agents.length; v++) {
			if (userAgentInfo.indexOf(Agents[v]) > 0) {
				flag = false;
				break;
			}
		}
		return !flag;
	},

	/**
	 * 修改html 的 title
	 * @param {String} text 要设置的字符串
	 */
	setTitle(text = '耀保网') {
		let titleTags = document.getElementsByTagName('title');
		if (titleTags.length) {
			let titleTag = titleTags[0];
			titleTag.innerHTML = text;
		}
	},

	/**
	 * 设置用户的 title
	 * @param {String} text 要设置的字符串
	 */
	setUserTitle(text = '') {
		let t = text ? text : 'ㅤ';
		let titleTags = document.getElementsByTagName('title');
		if (titleTags.length) {
			let titleTag = titleTags[0];
			titleTag.innerHTML = t;
		}
	},

	/**
	 * 是否为生产环境
	 * @returns Boolean
	 */
	isDev() {
		let hostname = window.location.hostname;
		let r = true;
		if (hostname.includes('192.168.') || hostname.includes('127.0.0.1')||hostname.includes('localhost')) {
			// 本地环境
			r = false;
		} else if (hostname.includes('testh5.')) {
			// 测试环境
			r = false;
		}
		return r;
	},

	/**
	 * 获取当前环境
	 * @returns String/undefined 微信环境，返回 wx; 支付宝环境，返回 zfb; 其它环境返回 undefined
	 */
	getEnv() {
		let env = undefined;

		let ua = window.navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') {
			env = 'wx';
		} else if (ua.match(/AlipayClient/i) == 'alipayclient') {
			env = 'zfb';
		}

		return env;
	},

	// 图片旋转处理 -> base64
	/**
	 * @param {String} src 源图片地址
	 * @param {Number} edg 旋转角度, 旋转角度必须是90的倍数
	 * @returns Promise resolve处理后图片src
	 */
	rotateBase64Img(src, edg) {
		return new Promise(resolve => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			let imgW = 0; // 图片宽度
			let imgH = 0; // 图片高度
			let size = 0; // canvas初始大小

			if (edg % 90 !== 0) {
				throw new Error('旋转角度必须是90的倍数!');
			}

			edg < 0 && (edg = (edg % 360) + 360);
			const quadrant = (edg / 90) % 4; // 旋转象限
			const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; // 裁剪坐标

			const image = new Image();
			image.src = src;
			image.crossOrigin = 'anonymous';

			image.onload = function () {
				imgW = image.width;
				imgH = image.height;
				size = imgW > imgH ? imgW : imgH;

				canvas.width = size * 2;
				canvas.height = size * 2;
				switch (quadrant) {
					case 0:
						cutCoor.sx = size;
						cutCoor.sy = size;
						cutCoor.ex = size + imgW;
						cutCoor.ey = size + imgH;
						break;
					case 1:
						cutCoor.sx = size - imgH;
						cutCoor.sy = size;
						cutCoor.ex = size;
						cutCoor.ey = size + imgW;
						break;
					case 2:
						cutCoor.sx = size - imgW;
						cutCoor.sy = size - imgH;
						cutCoor.ex = size;
						cutCoor.ey = size;
						break;
					case 3:
						cutCoor.sx = size;
						cutCoor.sy = size - imgW;
						cutCoor.ex = size + imgH;
						cutCoor.ey = size + imgW;
						break;
				}

				ctx.translate(size, size);
				ctx.rotate((edg * Math.PI) / 180);
				ctx.drawImage(image, 0, 0);

				const imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
				if (quadrant % 2 === 0) {
					canvas.width = imgW;
					canvas.height = imgH;
				} else {
					canvas.width = imgH;
					canvas.height = imgW;
				}
				ctx.putImageData(imgData, 0, 0);
				resolve(canvas.toDataURL('image/png', 0.7));
			};
		});
	},

	/**
	 * base64文件编码转文件流
	 * @param {String} dataurl Base64String
	 * @param {String} filename 文件名称
	 * @returns Blob
	 */
	base64toFile(dataurl, filename = 'file') {
		let arr = dataurl.split(',');
		let mime = arr[0].match(/:(.*?);/)[1];
		let suffix = mime.split('/')[1];
		let bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], `${filename}.${suffix}`, {
			type: mime,
		});
	},

    /**
     * 存储首页地址到本地存储，只存在各模块的首页地址，防止用户在各模块内部其它页面刷新时参数丢失
     */
    saveUrl() {
        localStorage.setItem('homeUrl', window.location.href)
    },
   /**
	 * 根据出生日期计算周岁年龄
	 * @param {String} birthdayString 出生日期字符串，或者日期格式
	 * @returns 年龄 number
	 */
	getAgeFromBirthday(birthdayString) {
		let birthdayDateObject = new Date(birthdayString);

		// 当前年份
		let currentYear = new Date().getFullYear();
		// 出生年份
		let birthdayYear = birthdayDateObject.getFullYear();
		// 按照年份计算年龄
		let age = currentYear - birthdayYear;

		// 今年周岁具体日期
		let currentYearBirthday = `${currentYear}-${birthdayDateObject.getMonth() + 1}-${birthdayDateObject.getDate()}`;

		// 判断是否过了生日
		if (Date.now() > new Date(currentYearBirthday).getTime()) {
			return age;
		} else {
			return age - 1;
		}
	},
	//翻译中文名
	translateName (nameStr) {
		return new Promise((resolve, reject) => {
			http_insTranslate(nameStr)
				.then(res => {
					if (res.data.success) {
						resolve(res.data.data);
					} else {
						reject();
					}
				})
				.catch(() => {
					reject();
				});
		});
	},

		/**
	 * 导出内容为txt文件
	 * @param {String} fileName 文件名称
	 * @param {String Array} val 导出的内容
	 */
	saveAsText(fileName, val) {
		var export_blob = new Blob([val]);
		var save_link = document.createElement('a');
		save_link.href = window.URL.createObjectURL(export_blob);
		save_link.download = fileName;
		this.fakeClick(save_link);
	},
	fakeClick(obj) {
		var ev = document.createEvent('MouseEvents');
		ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		obj.dispatchEvent(ev);
	},

	//设置今天/明天/后天
	calendarFormatter(day) {
		const now = moment().format('YYYY-MM-DD'); //今天
		const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
		const yestarday = moment().subtract(1, 'd').format('YYYY-MM-DD');
		const current = `${day.date.getFullYear()}-${String(day.date.getMonth() + 1).padStart(2, '0')}-${String(day.date.getDate()).padStart(2, '0')}`;
		if (now == current) {
			day.bottomInfo = '今天';
		}
		if (tomorrow == current) {
			day.bottomInfo = '明天';
		}
		if (yestarday == current) {
			day.bottomInfo = '昨天';
		}
		return day;
	},

	// 修改网页cps标题和logo
	setCpsLogo(){
		document.title=store.state.cps_brindInfo.brandName||'耀保网'
		store.state.cps_brindInfo.brandLabel&&document.querySelector('link[rel~="icon"]').setAttribute("href",store.state.cps_brindInfo.brandLabel)
	},
	/**
	 * 根据出生日期和传过来的日期计算周岁年龄
	 * @param {String} birthdayString 出生日期字符串，或者日期格式
	 * @returns 年龄 number
	 */
	getAgeFromStartTime(birthdayString,startDate) {
		let birthdayDateObject = new Date(birthdayString);
		// 当前年份
		let currentYear = new Date(startDate).getFullYear();
		// 出生年份
		let birthdayYear = birthdayDateObject.getFullYear();
		// 按照年份计算年龄
		let age = currentYear - birthdayYear;

		// 今年周岁具体日期
		let currentYearBirthday = `${currentYear}-${birthdayDateObject.getMonth() + 1}-${birthdayDateObject.getDate()}`;
		// 判断是否过了生日
		if (new Date(startDate).getTime()> new Date(currentYearBirthday).getTime()) {
			return age;
		} else {
			return age - 1;
		}
	},


}
