import Vue from 'vue';
import VueRouter from 'vue-router';
import base from "../assets/js/base.js"
// 产品详情
import productDetail from '../views/insure/productDetail.vue';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter);

// 路由配置参数中name的值不可重复

const routes = [
	// {
	//     path: '/',
	//     redirect: '/insure',
	// },

	// 投保
	{
		path: '/insure',
		redirect: '/insure/productDetail',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				path: 'productDetail',
				name: 'productDetail',
				component: productDetail,
			},
			{
				path: '/safeguardClause',
				name: 'safeguardClause',
				component: () => import('../views/insure/safeguardClause.vue'),
				props: true,
			},
			{
				path: 'createOrder',
				name: 'createOrder',
				component: () => import('../views/insure/createOrder.vue'),
				props: true,
			},
			{
				// 固定投保人信息
				path: 'createOrderFixed',
				name: 'createOrderFixed',
				component: () => import('../views/insure/createOrderFixed.vue'),
				props: true,
			},
			{
				path: 'addUser',
				name: 'addUser',
				component: () => import('../views/insure/addUser.vue'),
				props: true,
			},
			{
				path: 'orderPay',
				name: 'orderPay',
				component: () => import('../views/insure/orderPay.vue'),
				props: true,
			},
			{
				path: 'insurePaySuccess',
				name: 'insurePaySuccess',
				component: () => import('../views/insure/insurePaySuccess.vue'),
				props: true,
			},
			{
				path: 'payCode',
				name: 'payCode',
				component: () => import('../views/insure/payCode.vue'),
				props: true,
			},
			{
				path: 'orderSearch',
				name: 'orderSearch',
				component: () => import('../views/insure/orderSearch.vue'),
			},
			{
                path: "createOrderV2",
                name: "createOrderV2",
                component: ()=>import("../views/insureV2/createOrderV2.vue"),
                props: true,
            },
            {
                path: "addUserV2",
                name: "addUserV2",
                component: ()=>import("../views/insureV2/components/addUserV2.vue"),
                props: true,
            },
			
		],
	},

	// pro注册
	{
		path: '/proRegister',
		name: 'proRegister',
		component: () => import('../views/proRegister/baseInfo.vue'),
	},

	// 被保险人名单收集
	{
		path: '/insuredsCollect',
		component: () => import('../views/insuredsCollect/index.vue'),
	},

	// 支付
	{
		path: '/pay',
		component: () => import('../views/pay.vue'),
	},
	// 支付平台
	{
		path:"/payPlatform",
		component:()=>import('../views/payPlatform.vue')
	},
	{
		path:"/payPlatformSuccess",
		component:()=>import('../views/payPlatformSuccess.vue')
	},

	{
		path: '/paySuccess/:billId',
		component: () => import('../views/paySuccess.vue'),
	},
	{
		path: '/aLiPay',
		component: () => import('../views/aLiPay.vue'),
	},

	// 可配置信息的投保页面
	{
		path: '/fixedCodeInsure',
		redirect: '/fixedCodeInsure/index',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				// 首页
				path: 'index',
				name: 'fixedCodeIndex',
				component: () => import('../views/fixedCodeInsure/index.vue'),
				props: true,
			},
			{
				// 欢迎页
				path: 'welcome',
				name: 'fixedCodeWelcome',
				component: () => import('../views/fixedCodeInsure/welcome.vue'),
				props: true,
			},
			{
				// 安全告知书
				path: 'securityBook',
				name: 'fixedCodeSecurityBook',
				component: () => import('../views/fixedCodeInsure/securityBook.vue'),
				props: true,
			},
			{
				// 谁投保谁付款模式，注意：path要与name完全相同，因为代码中有用到
				path: 'indexOneselfPay',
				name: 'indexOneselfPay',
				component: () => import('../views/fixedCodeInsure/indexOneselfPay.vue'),
				props: true,
			},
			{
				// 会员支付模式，注意：path要与name完全相同，因为代码中有用到
				path: 'fixedCodeInsUserPay',
				name: 'fixedCodeInsUserPay',
				component: () => import('../views/fixedCodeInsure/indexInsUserPay.vue'),
				props: true,
			},
			{
				path: 'orderSearch',
				name: 'orderSearch',
				component: () => import('../views/insure/orderSearch.vue'),
			},
			{
				path: 'safeguardClause',
				name: 'safeguardClause',
				component: () => import('../views/insure/safeguardClause.vue'),
				props: true,
			},
			{
				path: 'addPassportUser',
				name: 'addPassportUser',
				component: () => import('../views/fixedCodeInsure/addPassportUser.vue'),
				props: true,
			},
			{
				path: 'fixedCodeOrderPay',
				name: 'fixedCodeOrderPay',
				component: () => import('../views/fixedCodeInsure/orderPay.vue'),
				props: true,
			},
			{
				path:"planDetail",
				name:"planDetail",
				component:()=>import("../views/fixedCodeInsure/planDetail.vue")
			}
		],
	},

	// 查单系统
	{
		path: '/billSearch',
		redirect: '/billSearch/login',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import('../views/billSearch/login.vue'),
			},
			{
				path: 'bill',
				name: 'bill',
				component: () => import('../views/billSearch/bill.vue'),
			},
		],
	},

	// 查单系统--微信小程序使用
	{
		path: '/billSearchWX',
		redirect: '/billSearchWX/login',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import('../views/billSearchWX/login.vue'),
			},
			{
				path: 'bill',
				name: 'bill',
				component: () => import('../views/billSearchWX/bill.vue'),
			},
		],
	},

	// 非旅游险投保功能，需要登录(其它险种，比较杂，暂时只有牛魔)
	{
		path: '/sundry',
		redirect: '/sundry/login',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				path: 'login',
				component: () => import('../views/sundry/login.vue'),
			},
			{
				path: 'register',
				component: () => import('../views/sundry/register.vue'),
			},
			{
				path: 'goods',
				component: () => import('../views/sundry/goods.vue'),
			},
			{
				path: 'product',
				component: () => import('../views/sundry/product.vue'),
			},
			{
				path: 'addUser',
				component: () => import('../views/sundry/addUser.vue'),
			},
			{
				path: 'addUser2',
				component: () => import('../views/sundry/addUser2.vue'),
			},
			{
				path: 'addUser3',
				component: () => import('../views/sundry/addUser3.vue'),
			},
			{
				// 订单列表
				path: 'bill',
				component: () => import('../views/sundry/bill.vue'),
			},
			{
				// 常用被保人列表
				path: 'assuredBook',
				component: () => import('../views/sundry/components/assuredBook'),
			},
			{
				// 常用被保人列表2
				path: 'assuredBook2',
				component: () => import('../views/sundry/components/assuredBook2'),
			},
			{
				// 装修工人意外险
				path: 'upfitter',
				component: () => import('../views/sundry/insure/upfitter.vue'),
			},
			{
				// 建筑工程一切险
				path: 'construction',
				component: () => import('../views/sundry/insure/construction.vue'),
			},
			{
				// 华安公众责任险
				path: 'HA_public',
				component: () => import('../views/sundry/insure/HA_public.vue'),
			},
			{
				// 华安小微企业财产综合险
				path: 'HA_property',
				component: () => import('../views/sundry/insure/HA_property.vue'),
			},
			{
				// 建筑施工企业雇主责任险  暂时不要了
				path: 'employer',
				component: () => import('../views/sundry/insure/employer.vue'),
			},
			{
				// 华泰团体意外险
				path: 'HT_groupaccident',
				component: () => import('../views/sundry/insure/HT_groupaccident.vue'),
			},
		],
	},

	// // 保单查询
	// {
	//     path: "/pFileSearch",
	//     name: "pFileSearch",
	//     component: () => import("../views/pFileSearch.vue"),
	// },
	{
		path: '/pFileSearch',
		redirect: '/pFileSearch/index',
		component: () => import('../views/moduleHome.vue'),
		children: [
			{
				path: 'index',
				component: () => import('../views/pFileSearchV2/index.vue'),
			},
			{
				path: 'list',
				name: 'pFileList',
				component: () => import('../views/pFileSearchV2/indexList.vue'),
			},
			{
				path: 'detail',
				name: 'pFileDetail',
				component: () => import('../views/pFileSearchV2/listDetail.vue'),
			},
		],
	},

	// cps
	{
		path: '/cps',
		name: 'cps',
		component: () => import('../views/cps/index.vue'),
		children: [
			{
				path: 'cpsHome',
				name: 'cpsHome',
				component: () => import('../views/cps/home.vue'),
				meta: {
					tab: true,
				},
				props: true,
			},
			{
				path: 'cpsProduct',
				name: 'cpsProduct',
				component: () => import('../views/cps/product.vue'),
				meta: {
					tab: true,
				},
			},
			{
				path: 'cpsProductDetail',
				name: 'cpsProductDetail',
				component: () => import('../views/cps/productDetail.vue'),
				meta: {
					tab: false,
				},
			},
			{
				path: 'cpspFileSearch',
				name: 'cpspFileSearch',
				component: () => import('../views/cps/pFileSearch.vue'),
				meta: {
					tab: true,
				},
			},
			{
				path: 'cpsCreateOrder',
				name: 'cpsCreateOrder',
				component: () => import('../views/cps/createOrder.vue'),
				props: true,
			},
			// cps订单页v2
			{
				path: 'cpsCreateOrderV2',
				name: 'cpsCreateOrderV2',
				component: () => import('../views/cps/createOrderV2.vue'),
				props: true,
			},
			{
				path: 'addUserV2',
				name: 'cpsAddUserV2',
				component: () => import('../views/cps/addUserV2.vue'),
				props: true,
			},
			
			{
				path: 'cpsOrderPay',
				name: 'cpsOrderPay',
				component: () => import('../views/cps/orderPay.vue'),
				props: true,
			},

			{
				path: 'cpsSafeguardClause',
				name: 'cpsSafeguardClause',
				component: () => import('../views/cps/safeguardClause.vue'),
				props: true,
			},

			// 新增被保险人
			{
				path: 'cpsAddUser',
				name: 'cpsAddUser',
				component: () => import('../views/cps/addUser.vue'),
				props: true,
			},
			{
				path: 'cpspaySuccess',
				name: 'cpspaySuccess',
				component: () => import('../views/cps/paySuccess.vue'),
				props: true,
			},
			// 教培
			{
				path: 'cpsjiaoPeiJiGou/index',
				name: 'cps_jiaoPeiJiGou_index',
				component: () => import('../views/cps/liability/jiaoPeiJiGou/index.vue'),
			},
			{
				path: 'cpsjiaoPeiJiGou/insure',
				name: 'cps_jiaoPeiJiGou_insure',
				component: () => import('../views/cps/liability/jiaoPeiJiGou/insure.vue'),
			},
			// 国任
			{
				path: 'cpsgrPublic/index',
				name: 'cpsGuoRen_index',
				component: () => import('../views/cps/liability/grzr/index.vue'),
			},
			{
				path: 'cpsgrPublic/insure',
				name: 'cpsGrCreateOrder',
				component: () => import('../views/cps/liability/grzr/insure.vue'),
			},
			{
				path: 'cpsgrPublic/AddUser',
				name: 'cpsGrAddUser',
				component: () => import('../views/cps/liability/grzr/addUser.vue'),
			},
			// 马术
			{
				path: 'cpsMsDuty/index',
				name: 'cps_msDuty_index',
				component: () => import('../views/cps/liability/maShu/index.vue'),
			},
			{
				path: 'cpsMsDuty/insure',
				name: 'cps_msDuty_insure',
				component: () => import('../views/cps/liability/maShu/insure.vue'),
			},
			// cps责任v2
			{
				path: 'cpsLiProduct',
				name: 'cpsLiProduct',
				component: () => import('../views/cps/liability/v2/liProduct.vue'),
			},
			{
				path: 'cpsLiOrderV2',
				name: 'cpsLiOrderV2',
				component: () => import('../views/cps/liability/v2/liCreateOrderV2.vue'),
			},
			
		],
	},

	// 责任险
	{
		path: '/liability',
		name: 'liability',
		component: () => import('../views/moduleHome.vue'),
		children: [
			// 由于有部分页面需要做缓存，所以不能使用子路由模式，
			// 因为子路由会有一个父级容器，父级容器如果不做缓存的话，容器中的内容无法做缓存；
			// 如果父级容器做缓存，容器中的所有内容都会被缓存起来

			// 教培机构责任险
			{
				path: 'jiaoPeiJiGou',
				name: 'jiaoPeiJiGou',
				redirect: 'jiaoPeiJiGou/index',
			},
			{
				path: 'jiaoPeiJiGou/index',
				name: 'jiaoPeiJiGou_index',
				component: () => import('../views/liability/jiaoPeiJiGou/index.vue'),
			},
			{
				path: 'jiaoPeiJiGou/insure',
				name: 'jiaoPeiJiGou_insure',
				component: () => import('../views/liability/jiaoPeiJiGou/insure.vue'),
			},

			// 国任责任险
			{
				path: 'grPublic',
				name: 'grPublic',
				redirect: 'grPublic/index',
			},
			{
				path: 'grPublic/index',
				name: 'guoRen_index',
				component: () => import('../views/liability/guoRen/index.vue'),
			},
			{
				path: 'grPublic/insure',
				name: 'grCreateOrder',
				component: () => import('../views/liability/guoRen/createOrder.vue'),
			},
			{
				path: 'grPublic/addUser',
				name: 'grAddUser',
				component: () => import('../views/liability/guoRen/addUser.vue'),
				props: true,
			},

			// 马术场地责任保险
			{
				path: 'msDuty',
				name: 'msDuty',
				redirect: 'msDuty/index',
			},
			{
				path: 'msDuty/index',
				name: 'msDuty_index',
				component: () => import('../views/liability/maShu/index.vue'),
			},
			{
				path: 'msDuty/insure',
				name: 'msDuty_insure',
				component: () => import('../views/liability/maShu/insure.vue'),
			},
			// 责任险v2
			{
				path:"indexV2",
				name:"liabilityIndexV2",
				component:()=>import("../views/liability/v2/indexV2.vue")
			},
			{
				path:"createOrderV2",
				name:"liabilityCreateOrderV2",
				component:()=>import("../views/liability/v2/liCreateOrderV2.vue")
			},
		
			{
				path: 'health',
				name: 'health',
				redirect: 'health/index',
			},
			{
				path: 'health/index',
				name: 'health_index',
				component: () => import('../views/liability/health/index.vue'),
			},
			{
				path: 'health/detail',
				name: 'health_detail',
				component: () => import('../views/liability/health/detail.vue'),
			},
			{
				path: 'health/moreDetail',
				name: 'health_more_detail',
				component: () => import('../views/liability/health/moreDetail.vue'),
			},
			{
				path: 'health/rateDetail',
				name: 'health_rate_detail',
				component: () => import('../views/liability/health/rateDetail.vue'),
			},
			{
				path: 'health/inform',
				name: 'health_inform',
				component: () => import('../views/liability/health/healthInform.vue'),
			},
			{
				path:"health/noInform",
				name: 'health_no_inform',
				component: () => import('../views/liability/health/noInform.vue'),
			},
			{
				path:"health/orderPay",
				name: 'health_order_pay',
				component: () => import('../views/liability/health/orderPay.vue'),
			},
			{
				path:"health/paySuccess",
				name: 'health_pay_success',
				component: () => import('../views/liability/health/paySuccess.vue'),
			}
		
			
		],
	},
	// crm自主报价
	{
		path:"/crm",
		name: 'crm',
		redirect:"/crm/crmProduct",
		component: () => import('../views/moduleHome.vue'),
		children:[{
			path:"crmProduct",
			name:"crmProduct",
			component:()=>import("../views/crm/crmProduct.vue")
		},
		{
			path:"pay",
			name:"crmPay",
			component:()=>import("../views/crm/pay.vue")
		}
	]
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.path.includes('/sundry/') || to.path.includes('/orderPay') || to.path.includes('/insurePaySuccess') || to.path.includes('/jiaoPeiJiGou')) {
		// 这个空白字符网上找来的，可靠性不太确定，index.html中的title也是这个字符
		document.title = 'ㅤ';
	} else if(to.path.includes("/cps")){
		base.setCpsLogo()
	}else if(to.path.includes('/pay')) {
        document.title = '支付中心';
    } else {
		document.title = '耀保网';
	}
	next();
});

export default router;
