// 视频可回溯功能，在main.js中引入即可
export default {
    start, stop, finish
}

import * as rrweb from "rrweb";
import { http_saveInsTracBack } from '../request/common'

// 用户id
let userId = undefined
// sessionId,第一次与后端交互的时间戳
let sessionId = undefined
let uuid = undefined

// 订单id
let bId = undefined

// 存储录制的数据
let data = []

// 等待上传的数据
let uploadData = []

// 上传数据的间隔时间，单位：秒
let intervalTime = 5
let interval = undefined

// 最大上传错误尝试次数，如果是-1，则不限次数
let maxErrorTimes = 20

// 停止录制方法
let stopRecod = undefined

// 正在进行http请求
let uploading = false

/**
 * 开始录制可回溯视频
 * @param {String} userId 用户id
 */
function start(userId) {
    if (!userId) {
        return console.error('请传入userid')
    }

    init(userId)
    stopRecod = rrweb.record({
        emit(e) {
            data.push(e)
        },
        packFn: rrweb.pack,
        inlineStylesheet: false
    })
    startUpload()
}

// 停止录制
function stop() {
    if (stopRecod) {
        stopRecod()
        clear()
    }
}

/**
 * 完成录制
 * @param {String} billId 订单id
 */
function finish(billId) {
    if (!billId) {
        return console.error('请传入订单id')
    }
    if (stopRecod) {
        stopRecod()
    }
    bId = billId
    // 立即上传剩下的所有数据
    // 如果定时器中的http请求正在执行，等待执行完毕，其方法内部会直接调用uploadAll方法
    // 如果定时器中的http没有在执行，直接清空定时器，执行uploadAll方法
    if (!uploading) {
        clearInterval(interval)
        uploadAll()
    }
}

// uuid的BASE64格式
function getUuid() {
    return window.btoa(`${userId}+${Date.now()}+${navigator.userAgent}`)
}

// 上传数据方法
function uploda(htmlData, billId = undefined) {
    return new Promise((resolve, reject) => {
        let temp = String(Date.now())
        if (!sessionId) {
            sessionId = temp
        }
        let send = {
            htmlData,
            transNo: uuid,
            timeStamp: temp,
            sessionId: sessionId
        }
        if (billId !== undefined) {
            send.billId = billId
        }
        http_saveInsTracBack(send).then(resolve).catch(reject)
    })
}

// 开始执行数据上传
function startUpload() {
    let time = intervalTime
    let maxTime = maxErrorTimes

    interval = setInterval(() => {
        time--
        if (time === 0) {
            if (data.length) {
                // 把录制的数据放入待上传的数据中
                uploadData.push({
                    data: JSON.stringify(data),
                    billId: bId
                })
                // 清空录制数据存储对象
                data = []
            }
            // 执行上传方法
            if (uploadData.length) {
                uploading = true
                uploda(uploadData[0].data, uploadData[0].billId)
                    .then(() => {
                        // 上传成功，删除已上传的数据
                        if (uploadData[0].billId !== undefined) {
                            // 上传完成
                            clear()
                        } else {
                            uploadData.shift()
                            maxTime = maxErrorTimes
                            if (bId !== undefined) {
                                // 已经执行“finish"操作了
                                clearInterval(interval)
                                uploadAll()
                            }
                        }
                    })
                    .catch(() => {
                        // 上传错误
                        if (maxTime !== -1) {
                            // 达到最大错误次数，停止上传
                            maxTime--
                            if (maxTime === 0) {
                                stop()
                            }
                        }
                    })
                    .finally(() => {
                        // 无论是否上传成功，倒计时时间都初始化，可以再次尝试
                        time = intervalTime
                        uploading = false
                    })
            } else {
                time = intervalTime
            }
        }
    }, 1000)
}

// 立即上传剩下的所有数据
function uploadAll(errortimes = 0) {
    if (data.length) {
        // 把录制的数据放入待上传的数据中
        uploadData.push({
            data: JSON.stringify(data),
            billId: bId
        })
        // 清空录制数据存储对象
        data = []
    }
    // 执行上传方法
    if (uploadData.length) {
        uploading = true
        uploda(uploadData[0].data, uploadData[0].billId)
            .then(() => {
                // 上传成功，删除已上传的数据
                if (uploadData[0].billId !== undefined) {
                    // 上传完成
                    clear()
                } else {
                    uploadData.shift()
                    uploadAll()
                }
            })
            .catch(() => {
                // 上传错误
                if (errortimes <= maxErrorTimes) {
                    // 最大错误次数以内，继续尝试上传
                    uploadAll(errortimes++)
                }else {
                    // 已达到最大尝试次数，不再继续尝试，清空数据
                    clear()
                }
            })
    }
}

// 清除数据
function clear() {
    data = []
    uploadData = []
    sessionId = undefined
    uuid = undefined
    bId = undefined
    stopRecod = undefined
    if (interval) {
        clearInterval(interval)
    }
    interval = undefined
}

// 初始化
function init(uid) {
    clear()
    userId = uid
    uuid = getUuid()
}