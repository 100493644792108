// H5投保页面接口
import { get, post, postUrl, postParams ,postFormData} from './http';

// 无账号登录
export function loginNoPwd(userStr) {
	return postUrl('/insurance/api/user/loginNoPwd', userStr);
}

// 账号登录
export function login(data) {
	return post('/insurance/api/user/login', data);
}

// 产品详情
export function productDetail(productId) {
	return postUrl('/insurance/api/product/details', productId);
}

// 获取产品条款
export function porductClause(productId,data) {
	return postFormData(`/insurance/api/product/productClauses/${productId}`, data);
}

// 获取配置信息
export function productDictInfo(productId, plainId) {
	return postUrl('/insurance/api/insure/queryInsProductPriceDistinctItemByCombId', [productId, plainId]);
}

// 创建保单，获取表单配置
export function orderFormConfig(productId) {
	return postUrl('/insurance/api/insure/findInsureFormList', productId);
}

// 获取固定配置信息（用来获取创建保单页面获取投保声明、个人信息保护政策）
export function fixedInfo() {
	return get('/insurance/api/common/findFixedInfo');
}

// 计算价格
export function getPrice(data) {
	return post('/insurance/api/insure/findProductPriceByKeysActualAge', data);
}

// 获取保险商信息
export function getInsuerInfo(productId, data) {
	return postUrl('/insurance/api/insure/insureInfo', productId, data);
}

// 提交订单
export function submitBill(data) {
	return post('/insurance/api/insure/saveBillSubmitForm', data);
}

// 获取微信二维码支付信息
export function getWxCodePay(billId) {
	return get('/insurance/api/pay/wechatNativePay', { billId });
}

// 获取微信H5支付信息
export function getWxH5Pay(billId) {
	return postUrl('/insurance/api/pay/wechatMWebPay', '', { billId });
}

// 获取微信JSAPI投保订单支付信息
export function getWxJSAPIPay(billId, openId) {
	return postUrl('/insurance/api/pay/wechatJsApiPay', '', { billId, openId });
}

// 获取微信JSAPI充值订单支付信息
export function getWxJSAPIRechargePay(orderId, openId) {
	return post(`/insurance/api/WechatRechargeMoney/getPaymentParameters/${openId}/${orderId}`);
}

// 查询订单支付详情
export function getBillPaymentInfo(billId) {
	return postUrl('/insurance/api/order/loadInsBillStateInfo', billId);
}

// 获取订单详情
export function getBillInfo(billId) {
	return postUrl('/insurance/api/order/queryUserBillInfoById', billId);
}

// 获取订单详情，多个id
export function getBillsInfo(billIds) {
	return postParams('/insurance/api/order/queryUserBillInfoByIds', { billIds });
}

// 获取订单状态
export function getBillState(billId) {
	return post(`/insurance/api/order/loadInsBillStateInfo/${billId}`);
}

// 获取公众号参数
export function getWxInfo() {
	return post('/insurance/api/pay/getWxInfo');
}

// 获取openid
export function getOpenId(code) {
	return get('/insurance/api/wx/getOpenId', { code });
}

// 解析名单
export function analysisNameString(nameString) {
	return post('/insurance/api/insure/analysisInsuredList', { insuredStr: nameString });
}

// 订单支付详情，支持传入多个订单id，返回订单信息与总支付金额
export function getPayInfo(ids) {
	return postParams('/insurance/api/order/payBillPageInfo', { billIds: ids });
}

// 根据被保险人信息查询订单
export function getOrderByAssured({ name, cardNum }) {
	return get(`/insurance/api/order/queryUserBillListByInsured/${name}/${cardNum}`);
}

// 修改因超时未支付的失效单的保障期限
export function updateInsureRange(data) {
	return post('/insurance/api/order/updateInsurancePeriod', data);
}

// 编码支付
export function codePay(billId, code) {
	return post('/insurance/api/pay/codePay', { billId, code });
}

// 根据订单id查询保单信息
export function getPFileInfoById(billId) {
	return get(`/insurance/api/insBillPfile/getPolicy/${billId}`);
}
// 查询费率
export function http_getProductComboConfig(data) {
	return post('/insurance/api/v2/insure/getProductComboConfig', data);
}
// 支付链接
export function http_getInsPayUrl(id) {
	return post(`/insurance/api/insPayUrl/getInsPayUrl/${id}`,);
}