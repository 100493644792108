<style lang="scss" scoped>
.loading_box {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
</style>

<template>
	<div>
		<van-overlay :show="show" z-index="3001">
			<div class="loading_box">
				<van-loading type="spinner" vertical>
					<slot></slot>
				</van-loading>
			</div>
		</van-overlay>
	</div>
</template>

<script>
import { Loading, Overlay } from 'vant';

export default {
	name: 'loading', // loading组件
	components: {
		[Loading.name]: Loading,
		[Overlay.name]: Overlay,
	},
	props: {
		show: {
			type: [Boolean, String, Number],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			loading: true,
		};
	},
	created() {},
};
</script>
